<template>
  <div>
    <v-container>
      <v-card flat
        ><v-card-title>Ledger5 - Wealth Tracking & Planning</v-card-title>
        <v-card-text
          >Ledger5 Wealth Tracking & Planning is currently in closed Beta.
        </v-card-text></v-card
      >
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>
